<script>
import { Upload } from 'ant-design-vue'
export default {
  props: ['value', 'item', 'mode', 'styles'],
  data() {
    return {
      sc: require('../../../assets/View/sc.png')
    }
  },
  methods: {
    handlePreview() {},
    getImgUrl(list) {
      const arr = []
      const { url } = JSON.parse(localStorage.getItem('qiniu')) || {}
      list.forEach(e => {
        if (e.status == 'done') {
          if (e.hash) {
            arr.push(url + e.hash)
          } else if (e.url) {
            arr.push(e.url)
          } else {
            if (e.response && e.response.hash) {
              arr.push(url + e.response.hash)
            }
          }
        }
      })
      return arr.join(',')
    },
    getFileList(url) {
      if (Array.isArray(url)) return url
      if (!url) return []
      return url
        .split(',')
        .filter(e => e)
        .map(e => {
          if (!e) return
          const name = e.split('?')[0].split('/')[3]
          return {
            uid: 'vc-upload-' + name,
            name,
            status: 'done',
            url: e,
            thumbUrl: e
          }
        })
    },
    handleChange(data) {
      //   console.log('data.fileList', data.fileList)
      if (data.file.status == 'uploading') {
        this.$emit('change', data.fileList)
      } else {
        this.$emit('change', this.getImgUrl(data.fileList))
      }
    },
    renderButton() {
      return (
        <div>
          <a-icon type="plus" />
          <div class="ant-upload-text">上传</div>
        </div>
      )
    },
    renderUploadItem(file) {
      if (file.indexOf('mp4') !== -1) {
        return <video src={file} />
      }
      return <img src={file} />
    }
  },
  render() {
    const { token } = JSON.parse(localStorage.getItem('qiniu')) || {}
    return (
      <Upload.Dragger
        name="file"
        action="http://upload.qiniup.com/"
        fileList={this.getFileList(this.value)}
        data={{ token }}
        onChange={this.handleChange}
        renderUploadItem={this.renderUploadItem}
        multiple={true}
      >
        <div class="boxx">
          <img class="img_box" src={this.sc} />
          <span>将文件拖到此处上传</span>
        </div>
      </Upload.Dragger>
    )
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-upload.ant-upload-drag {
  height: 120px;
  border: 1px dashed #ddd;
  background-color: #fff;
  border-radius: 10px;
}
.boxx {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    font-family: PingFang-SC-ExtraLight, 'PingFang SC ExtraLight', 'PingFang SC',
      sans-serif;
    color: rgb(81, 90, 110);
  }

  .img_box {
    width: 50px;
  }
}
</style>
